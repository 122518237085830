import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../../components";

const SocialMedia = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Social Media",
        href: pathname,
        lang: "en"
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/social-media/",
      }}
    >
      <section className="container-fluid social_section">
        <div className="text-center">
          <h1>Social Media</h1>
        </div>
        <div className="row social_row">
          <div className="col-md-9 m-auto">
            <p className="text-center">
              We run brand profiles on Facebook, Instagram, and Linkedin. As part of our activities, we prepare the graphic design of the profile, create a communication strategy tailored to your audience, and conduct a discussion that builds fan engagement in the network. We moderate the community, increase the number of valuable fans and react quickly to any crises. We support organic activities with paid campaigns that affect the company's image and increase sales.
            </p>
          </div>
        </div>
        <div className="row social_row social_img">
          <div className="col-md-8 m-auto">
            <div className="row">
              <div className="col-md-4 text-center mb-4">
                <img
                  className="img-fluid m-auto"
                  src={require("../../../assets/img/offer/facebook.png")}
                  alt=""
                />
              </div>
              <div className="col-md-4 text-center mb-4">
                <img
                  className="img-fluid m-auto"
                  src={require("../../../assets/img/offer/instagram.png")}
                  alt=""
                />
              </div>
              <div className="col-md-4 text-center mb-4">
                <img
                  className="img-fluid m-auto"
                  src={require("../../../assets/img/offer/linkedin.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row social_row">
          <div className="col-md-9 m-auto">
            <p className="text-center">
              All our undertakings in the area of ​​social media are based on an individual communication strategy. They contain a comprehensive approach to the brand's image, its target groups, and communication channels within which it should operate. When preparing the strategy, we analyze the competition and market opportunities in detail. On the basis of the conclusions drawn, we define the goal and a specific concept.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Other services</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Web Analytics</span>
            <Link to="/en/offer/web-analytics/">See</Link>
          </div>
          <div className="col-md-2 box">
            <span>Banner ads</span>
            <Link to="/en/offer/banner-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/en/offer/linkedin-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/en/offer/facebook-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/en/offer/google-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>International Marketing Campaigns</span>
            <Link to="/en/offer/international-campaigns/">see</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default SocialMedia;
